html,
body {
    height: 100%;
    width: 100%;
}

body {
    @include serif-font;
}

hr {
    border-color: $theme-primary;
    border-width: 3px;
    max-width: 50px;
}

hr.light {
    border-color: white;
}

a {
    @include transition-all;
    color: $theme-primary;
    &:hover,
    &:focus {
        color: darken($theme-primary, 10%);
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include sans-serif-font;
    text-align: center;
}

p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
}

.bg-primary {
    background-color: $theme-primary;
}

.bg-primary-1 {
    background-color: $theme-primary-1;
}

.bg-primary-2 {
    background-color: $theme-primary-2;
}

.bg-primary-3 {
    background-color: $theme-primary-3;
}

.bg-primary-4 {
    background-color: $theme-primary-4;
}
.img-fluid {
  max-width: 100%;
  height: auto;
  }

.img-fluid-in-post {
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
  }

.bg-dark {
    background-color: $theme-dark;
    color: white;
}

.text-faded {
    color: rgba(white, .7);
}

section {
    padding: 100px 0;
}

aside {
    padding: 50px 0;
}

.no-padding {
    padding: 0;
}

// Navigation

.navbar-default {
    background-color: white;
    border-color: rgba($theme-dark, .5);
    @include sans-serif-font;
    @include transition-all;
    .navbar-header .navbar-brand {
        color: $theme-primary;
        @include sans-serif-font;
        font-weight: 700;
        text-transform: uppercase;
        &:hover,
        &:focus {
            color: darken($theme-primary, 10%);
        }
    }
    .nav {
        > li {
            > a,
            > a:focus {
                text-transform: uppercase;
                font-weight: 700;
                font-size: 13px;
                color: $theme-dark;
                &:hover {
                    color: $theme-primary;
                }
            }
            &.active {
                > a,
                > a:focus {
                    color: $theme-primary !important;
                    background-color: transparent;
                    &:hover {
                        background-color: transparent;
                    }
                }
            }
        }
    }
    @media (min-width: 768px) {
        background-color: transparent;
        border-color: rgba(white, .3);
        .navbar-header .navbar-brand {
            color: rgba(white, .7);
            &:hover,
            &:focus {
                color: white;
            }
        }
        .nav > li > a,
        .nav > li > a:focus {
            color: rgba(white, .7);
            &:hover {
                color: white;
            }
        }
        &.affix {
            background-color: white;
            border-color: rgba($theme-dark, .5);
            .navbar-header .navbar-brand {
                color: $theme-primary;
                font-size: 14px;
                &:hover,
                &:focus {
                    color: darken($theme-primary, 10%);
                }
            }
            .nav > li > a,
            .nav > li > a:focus {
                color: $theme-dark;
                &:hover {
                    color: $theme-primary;
                }
            }
        }
    }
}

// Homepage Header

header {
    position: relative;
    width: 100%;
    min-height: auto;
    @include background-cover;
    background-position: center;
    background-image: url('../img/header.jpg');
    text-align: center;
    color: white;
    .header-content {
        position: relative;
        text-align: center;
        padding: 100px 15px 100px;
        width: 100%;
        .header-content-inner {
            h1 {
                font-weight: 700;
                text-transform: uppercase;
                margin-top: 0;
                margin-bottom: 0;
            }
            hr {
                margin: 30px auto;
            }
            p {
                font-weight: 300;
                color: rgba(white, .7);
                font-size: 16px;
                margin-bottom: 50px;
            }
        }
    }
    @media (min-width: 768px) {
        min-height: 100%;
        .header-content {
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            padding: 0 50px;
            .header-content-inner {
                max-width: 1000px;
                margin-left: auto;
                margin-right: auto;
                p {
                    font-size: 18px;
                    max-width: 80%;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}

// Sections

.section-heading {
    margin-top: 0;
}

.service-box {
    max-width: 400px;
    margin: 50px auto 0;
    @media (min-width: 992px) {
        margin: 20px auto 0;
    }
    p {
        margin-bottom: 0;
    }
}

.portfolio-box {
    position: relative;
    display: block;
    max-width: 650px;
    margin: 0 auto;
    .portfolio-box-caption {
        color: white;
        opacity: 0;
        display: block;
        background: rgba( $theme-primary, .9 );
        position: absolute;
        bottom: 0;
        text-align: center;
        width: 100%;
        height: 100%;
        @include transition-all;
        .portfolio-box-caption-content {
            width: 100%;
            text-align: center;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            .project-category,
            .project-name {
                @include sans-serif-font;
                padding: 0 15px;
            }
            .project-category {
                text-transform: uppercase;
                font-weight: 600;
                font-size: 14px;
            }
            .project-name {
                font-size: 18px;
            }
        }
    }
    &:hover {
        .portfolio-box-caption {
            opacity: 1;
        }
    }
    @media (min-width: 768px) {
        .portfolio-box-caption {
            .portfolio-box-caption-content {
                .project-category {
                    font-size: 16px;
                }
                .project-name {
                    font-size: 22px;
                }
            }
        }
    }
}

.call-to-action {
    h2 {
        margin: 0 auto 20px;
    }
}

// Bootstrap Overrides
.text-primary {
    color: $theme-primary;
}

.no-gutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
}

// Button Styles
.btn-default {
    @include button-variant($theme-dark, white, white);
}

.btn-primary {
    @include button-variant(white, $theme-primary, $theme-primary);
}

.btn {
    @include sans-serif-font;
    border: none;
    border-radius: 300px;
    font-weight: 700;
    text-transform: uppercase;
}

.btn-xl {
    padding: 15px 30px;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

// Contact
#contact .fa {
    color: $theme-dark;
    font-size: 4em;
}

// Extras
// -- Highlight Color Customization
::-moz-selection {
    color: white;
    text-shadow: none;
    background: $theme-dark;
}

::selection {
    color: white;
    text-shadow: none;
    background: $theme-dark;
}

img::selection {
    color: white;
    background: transparent;
}

img::-moz-selection {
    color: white;
    background: transparent;
}

body {
    -webkit-tap-highlight-color: $theme-dark;
}

// added by leemenix: 24 04 2020
// https://simplesharingbuttons.com/#code
/* Share Bar */
#share-bar {
    font-size: 20px;
}

/* Title */
#share-bar h4 {
    margin-bottom: 10px;
    font-weight: 500;
}

/* All buttons */
.share-buttons {
}

/* Each button */
.share-button {
    margin: 0px;
    margin-bottom: 10px;
    margin-right: 3px;
    border: 1px solid #D3D6D2;
    padding: 5px 10px 5px 10px;
}
.share-button:hover {
    opacity: 1;
    color: #ffffff;
}

.share-buttons a{
    text-decoration: none;
}

/* Facebook button */
.fa-facebook-official {
    color: #3b5998;
}
.fa-facebook-official:hover {
    background-color: #3b5998;
}

/* Twitter button */
.fa-twitter {
    color: #55acee;
}
.fa-twitter:hover {
    background-color: #55acee;
}

/* Google-PLus button */
.fa-google-plus {
    color: #dd4b39;
}
.fa-google-plus:hover {
    background-color: #dd4b39;
}

/* Pinterest button */
.fa-pinterest-p {
    color: #cb2027;
}
.fa-pinterest-p:hover {
    background-color: #cb2027;
}

/* Tumblr button */
.fa-tumblr {
    color: #32506d;
}
.fa-tumblr:hover {
    background-color: #32506d;
}

/* Reddit button */
.fa-reddit-alien {
    color: #ff4500;
}
.fa-reddit-alien:hover {
    background-color: #ff4500;
}

/* LinkedIn button */
.fa-linkedin {
    color: #007bb5;
}
.fa-linkedin:hover {
    background-color: #007bb5;
}

/* Instagram button */
.fa-instagram {
    color: #517fa4;
}
.fa-instagram:hover {
    background-color: #517fa4;
}

/* Email button */
.fa-envelope {
    color: #444444;
}
.fa-envelope:hover {
    background-color: #444444;
}

//https://stackoverflow.com/questions/19204902/sticky-responsive-footer-jekyll-bootstrap
$footerHeight: 75px;
#page-wrap {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    // Negative indent footer by it's height
    margin-bottom: -($footerHeight);
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
}

//footer fixed height
.footer-push,
footer {
    height: $footerHeight;
    max-height: $footerHeight;
    overflow: hidden;
}

.footer-heart{text-align:center;font-family: open sans, sans-serif;}

.footer-heart a{border-bottom: 1px solid #453886;color: #453886;padding-bottom: .25em; text-decoration: none;}

.footer-heart a:hover{color:#2196f3; background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .3s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%23453886' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: 20%;
  border-bottom: 0;
  padding-bottom: .3em;
  text-decoration: none;}

.emoji{vertical-align: middle;}